import React from 'react';
import { ListItem, ListItemText, ListItemIcon, Typography, Box } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import dayjs from 'dayjs';
import { Transaction } from 'types/Shop';
import { MonetizationOn } from '@mui/icons-material';

interface TransactionListItemProps {
  transaction: Transaction;
}

const TransactionListItem: React.FC<TransactionListItemProps> = ({ transaction }) => {
  // Determine if the transaction is a credit or debit based on the amount.
  const isCredit = transaction.amount > 0;
  const icon = isCredit ? <MonetizationOn color="success" /> : <MonetizationOn color="error" />;
  const formattedAmount = `$${Math.abs(transaction.amount).toFixed(2)}`;

  const getSourceTypeString = (sourceType: string) => {
    switch (sourceType) {
      case 'branch_order_fee':
        return 'Comisión de orden';
      case 'branch_cancel_fee':
        return 'Comisión de cancelación';
      case 'deposit':
        return 'Deposito';
      default:
        return sourceType;
    }
  };

  return (
    <ListItem divider>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText
        primary={
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle1">
              {getSourceTypeString(transaction.sourceType)}
            </Typography>
            <Typography variant="subtitle1" fontWeight="bold">
              {isCredit ? '+' : '-'}
              {formattedAmount}
            </Typography>
          </Box>
        }
        secondary={
          <Box>
            {transaction.sourceType === 'branch_order_fee' && (
              <Typography variant="body2" color="text.secondary">
                Venta: ${transaction?.branchOrder?.productsTotal}
              </Typography>
            )}

            <Typography variant="caption" color="text.secondary">
              {dayjs(transaction.createdAt).format('MMM D, YYYY h:mm A')}
            </Typography>
          </Box>
        }
      />
    </ListItem>
  );
};

export default TransactionListItem;
