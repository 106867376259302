import React, { useEffect } from 'react';
import { Typography, Box, CircularProgress, Divider, Pagination, Grid } from '@mui/material';
import chaletApi from 'api/chaletApi';
import { useAppSelector } from 'redux/hook';
import FeedbackCard from './FeedbackCard';

const Feedbacks: React.FC = () => {
  const { selectedBranch } = useAppSelector((state) => state.user);

  const [getFeedbacksByBranch, { data, isError, isLoading }] =
    chaletApi.useLazyGetFeedbackByBranchQuery();
  const [page, setPage] = React.useState(1);

  useEffect(() => {
    if (selectedBranch) {
      getFeedbacksByBranch({
        branchId: selectedBranch.id,
        page,
        limit: 10
      });
    }
  }, [selectedBranch, page]);

  const handlePageChange = (_: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh" width="100%">
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh" width="100%">
        <Typography variant="h6" color="error">
          Error loading feedbacks.
        </Typography>
      </Box>
    );
  }

  return data?.data ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '85vh',
        width: '100%',
        overflowY: 'hidden',
        px: 2,
        pb: 2
      }}>
      <Typography variant="h5" gutterBottom>
        Feedbacks de sucursal {selectedBranch?.name}
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Box sx={{ flex: 1, overflowY: 'auto' }}>
        <Grid container spacing={2}>
          {data.data.feedbacks
            .slice()
            .sort(
              (a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            )
            .map((feedback: any) => (
              <FeedbackCard key={feedback.id} feedback={feedback} />
            ))}
        </Grid>

        {data.data.feedbacks.length === 0 && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              py: 4,
              textAlign: 'center',
              color: 'text.secondary'
            }}>
            <Box
              sx={{
                fontSize: 64,
                color: 'primary.main',
                mb: 2
              }}>
              <span role="img" aria-label="No feedback">
                📭
              </span>
            </Box>
            <Typography variant="h6" fontWeight="bold">
              No hay feedback disponible
            </Typography>
            <Typography variant="body2" mt={1}>
              Parece que esta sucursal aún no tiene comentarios. <br />
              Intenta más tarde para ver actualizaciones.
            </Typography>
          </Box>
        )}
      </Box>

      <Box display="flex" justifyContent="center" mt={2}>
        <Pagination
          count={data.data.totalPages || 1}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </Box>
  ) : null;
};

export default Feedbacks;
