import React, { useEffect, useState } from 'react';
import { Order } from 'types/Order';
import { OrderDetailItem } from './OrderDetailItem';
import chaletApi from 'api/chaletApi';
import toast from 'react-hot-toast';
import { Button, Stack, Typography } from '@mui/material';
import { toCurrencyFormat } from 'utils/formatter';

type OrderDetailsProps = {
  onOrderDetailUpdate?: (needsUpdate: boolean) => void;
  refetchOrders?: () => void;
  isWaitingUserConfirmation?: boolean;
  needsOrderUpdate?: boolean;
  order: Order;
};

export const OrderDetailsView: React.FC<OrderDetailsProps> = ({
  onOrderDetailUpdate,
  refetchOrders,
  isWaitingUserConfirmation,
  needsOrderUpdate,
  order
}) => {
  const [requestOrderUpdate] = chaletApi.useRequestOrderUpdateMutation();
  const isConfirmed = order.currentOrderProcessStep.correlative > 1;
  const groupedVariations: Record<
    number,
    Record<string, { value: string; addedValue: number }[]>
  > = {};

  const { id: orderId, details, productsTotal } = order;
  const obj: Record<number, { isOutOfStock: boolean; notes: string }> = {};
  details.forEach((orderDetail) => {
    const note = orderDetail.branchNotes?.[orderDetail.branchNotes?.length - 1]?.note || '';

    obj[orderDetail.id] = {
      isOutOfStock: orderDetail.status === 'out_of_stock',
      notes: note
    };
  });
  const [orderDetailsData, setOrderDetailsData] = useState(obj);

  useEffect(() => {
    const updatedOrderDetailsData: Record<number, { isOutOfStock: boolean; notes: string }> = {};
    details.forEach((orderDetail) => {
      const note = orderDetail.branchNotes?.[orderDetail.branchNotes?.length - 1]?.note || '';
      updatedOrderDetailsData[orderDetail.id] = {
        isOutOfStock: orderDetail.status === 'out_of_stock',
        notes: note
      };
    });
    console.log('updatedOrderDetailsData', updatedOrderDetailsData);
    setOrderDetailsData(updatedOrderDetailsData);
    let needsUpdate = false;

    Object.values(updatedOrderDetailsData).forEach((item) => {
      if (item.isOutOfStock) {
        needsUpdate = true;
      }
    });
    onOrderDetailUpdate && onOrderDetailUpdate(needsUpdate);
  }, [details]);

  details.forEach((orderDetail) => {
    if (!groupedVariations[orderDetail.id]) {
      groupedVariations[orderDetail.id] = {};
    }

    orderDetail.variations?.forEach((variation) => {
      const variationName = variation.option.variation.name;

      if (!groupedVariations[orderDetail.id][variationName]) {
        groupedVariations[orderDetail.id][variationName] = [];
      }

      groupedVariations[orderDetail.id][variationName].push(variation.option);
    });
  });

  const handleRequestOrderUpdate = () => {
    requestOrderUpdate({
      orderId,
      orderDetails: orderDetailsData
    })
      .unwrap()
      .then(() => {
        toast.success('Se ha enviado la solicitud de actualización');
        refetchOrders && refetchOrders();
      })
      .catch(() => {
        toast.error('Ocurrió un error al completar el paso');
      });
  };

  return (
    <>
      {details.map((orderDetail) => (
        <OrderDetailItem
          key={orderDetail.id}
          isConfirmed={isConfirmed}
          groupedVariations={groupedVariations}
          orderDetail={orderDetail}
          isWaitingUserConfirmation={isWaitingUserConfirmation}
          orderDetailData={orderDetailsData[orderDetail.id]}
          setOrderDetailData={(data) => {
            setOrderDetailsData((prev) => {
              const newObj = {
                ...prev,
                [orderDetail.id]: data
              };
              let needsUpdate = false;
              Object.values(newObj).forEach((item) => {
                if (item.isOutOfStock) {
                  needsUpdate = true;
                }
              });
              onOrderDetailUpdate && onOrderDetailUpdate(needsUpdate);
              return newObj;
            });
          }}
        />
      ))}
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Typography variant="h6">Total</Typography>
        <Typography variant="h6">{toCurrencyFormat(parseFloat(productsTotal))}</Typography>
      </Stack>
      {isWaitingUserConfirmation && (
        <Typography variant="h6" color="warning.main">
          Esperando confirmación del usuario
        </Typography>
      )}
      {!isWaitingUserConfirmation && needsOrderUpdate && (
        <Button variant="contained" onClick={handleRequestOrderUpdate}>
          Enviar actualización
        </Button>
      )}
    </>
  );
};
