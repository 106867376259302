import React, { useMemo } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  List,
  Divider,
  Card,
  CardContent,
  IconButton
} from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { useAppSelector } from 'redux/hook';
import { sortBy } from 'lodash';
import chaletApi from 'api/chaletApi';
import TransactionListItem from './TransactionListItem';
import { RefreshRounded } from '@mui/icons-material';

const BranchWallet: React.FC = () => {
  const { selectedBranch } = useAppSelector((state) => state.user);
  const {
    data: branchData,
    isLoading,
    isFetching,
    isError,
    refetch
  } = chaletApi.useGetBranchFinancialQuery(selectedBranch?.id || 0);

  // sortedTransactions

  const sortedTransactions = useMemo(() => {
    return sortBy(branchData?.wallet?.transactions, 'id').reverse();
  }, [branchData?.wallet]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (isError || !branchData || !branchData.wallet) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography variant="h6" color="error">
          Error loading wallet information.
        </Typography>
      </Box>
    );
  }

  const { wallet } = branchData;

  return (
    <Box p={2}>
      {/* Wallet Summary */}
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Box display="flex" alignItems="center">
            <Box alignItems="center" display="flex" flex={1}>
              <AccountBalanceWalletIcon sx={{ fontSize: 40, mr: 2, color: 'primary.main' }} />
              <Typography variant="h5" fontWeight="bold" sx={{ color: 'primary.main' }}>
                ${wallet.currentBalance}
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={() => refetch()} color="primary">
                <RefreshRounded />
              </IconButton>
            </Box>
          </Box>
        </CardContent>
      </Card>
      {isFetching && (
        <Box padding={4} display="flex" flex={1}>
          <CircularProgress />
        </Box>
      )}
      <Typography variant="h6" gutterBottom>
        Transacciones
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <List>
        {wallet.transactions && wallet.transactions.length > 0 ? (
          sortedTransactions.map((transaction) => (
            <TransactionListItem key={transaction.id} transaction={transaction} />
          ))
        ) : (
          <Typography variant="body2" color="text.secondary">
            No transactions available.
          </Typography>
        )}
      </List>
    </Box>
  );
};

export default BranchWallet;
