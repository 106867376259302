import React from 'react';
import {
  Card,
  CardContent,
  Collapse,
  IconButton,
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Stack
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { OrderDetail, OrderDetailStatuses } from 'types/Order';
import { toCurrencyFormat } from 'utils/formatter';
import { WarningAmber } from '@mui/icons-material';

type OrderDetailItemProps = {
  orderDetail: OrderDetail;
  groupedVariations: Record<number, Record<string, { value: string; addedValue: number }[]>>;
  orderDetailData: { isOutOfStock: boolean; notes: string };
  setOrderDetailData: (data: { isOutOfStock: boolean; notes: string }) => void;
  isWaitingUserConfirmation?: boolean;
  isConfirmed: boolean;
};

const getStatusName = (status: OrderDetailStatuses) => {
  switch (status) {
    case 'out_of_stock': {
      return 'Sin stock';
    }
    case 'pending': {
      return '';
    }
    case 'processed': {
      return '';
    }
    case 'replaced': {
      return 'Reemplazado';
    }
  }
};

export const OrderDetailItem: React.FC<OrderDetailItemProps> = ({
  orderDetail,
  isConfirmed,
  groupedVariations,
  orderDetailData,
  setOrderDetailData,
  isWaitingUserConfirmation
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const handleToggle = () => setExpanded((prev) => !prev);
  const readableName = getStatusName(orderDetail.status);
  return (
    <Card variant="outlined" sx={{ marginBottom: 2, position: 'relative' }}>
      {readableName.length > 0 && <Box sx={{ height: 16 }} />}
      <CardContent onClick={handleToggle} sx={{ cursor: 'pointer' }}>
        <Stack direction="row" alignItems="center" width="100%">
          <Stack direction="column" flex={1} alignItems="flex-start" sx={{ backgroundClip: 'red' }}>
            <Typography sx={{ fontSize: 14 }}>
              {orderDetail.name} -- {toCurrencyFormat(orderDetail.price)} x{orderDetail.quantity}
            </Typography>
            {orderDetail.variations && orderDetail.variations.length > 0 && (
              <Box>
                {Object.entries(groupedVariations[orderDetail.id]).map(
                  ([variationName, options]) => (
                    <Typography key={variationName} sx={{ fontSize: 12 }} color="text.secondary">
                      {variationName}:{' '}
                      {options
                        .map((option: any) => `${option.value} ($${option.addedValue})`)
                        .join(', ')}
                    </Typography>
                  )
                )}
              </Box>
            )}
            <Typography sx={{ fontSize: 12 }} color={'text.secondary'} gutterBottom>
              Notas: {orderDetail.notes ? orderDetail.notes : 'Sin notas adicionales'}
            </Typography>
          </Stack>
          <Box>
            <Typography sx={{ fontSize: 14 }}>
              {toCurrencyFormat(orderDetail.price * orderDetail.quantity)}
            </Typography>
          </Box>
          {!isConfirmed && (
            <Box>
              <IconButton>
                <ExpandMoreIcon />
              </IconButton>
            </Box>
          )}
        </Stack>
      </CardContent>
      <Collapse in={expanded && !isConfirmed} timeout="auto" unmountOnExit>
        <CardContent>
          <Stack direction={'row'} spacing={1}>
            {orderDetailData.isOutOfStock && (
              <TextField
                disabled={isWaitingUserConfirmation}
                sx={{ flex: 1 }}
                label="Comentarios, otras opciones, disponibles"
                multiline
                rows={3}
                margin="normal"
                variant="outlined"
                value={orderDetailData.notes}
                onChange={(e) => setOrderDetailData({ ...orderDetailData, notes: e.target.value })}
              />
            )}
            <FormControlLabel
              disabled={isWaitingUserConfirmation}
              control={
                <Checkbox
                  onChange={() =>
                    setOrderDetailData({
                      ...orderDetailData,
                      isOutOfStock: !orderDetailData.isOutOfStock
                    })
                  }
                  checked={orderDetailData.isOutOfStock}
                />
              }
              label="No disponible"
            />
          </Stack>
        </CardContent>
      </Collapse>
      {readableName.length > 0 && (
        <Box
          position="absolute"
          top={0}
          right={0}
          p={1}
          sx={{
            backgroundColor: 'warning.main',
            color: 'white',
            alignItems: 'center',
            display: 'flex',
            gap: 1
          }}>
          <WarningAmber />
          <Typography variant="body2">{readableName}</Typography>
        </Box>
      )}
    </Card>
  );
};
