import React, { useState, useEffect } from 'react';
import { Box, Button, Card, CardContent, Modal, Stack, Typography, useTheme } from '@mui/material';
import { Order } from 'types/Order';
import { OrderDetailsView } from '../OrderDetailsView/OrderDetailsView';
import PersonIcon from '@mui/icons-material/Person';
import chaletApi from 'api/chaletApi';
import toast from 'react-hot-toast';
import { RejectOrder } from './RejectOrder';
import { useAppSelector } from 'redux/hook';

type OrderModalProps = {
  order: Order;
  handleClose: () => void;
  isModalOpen: boolean;
  refetchOrders: () => void;
  isWaitingUserConfirmation?: boolean;
};

const OrderModal: React.FC<OrderModalProps> = ({
  order,
  handleClose,
  isModalOpen,
  refetchOrders,
  isWaitingUserConfirmation
}) => {
  const { selectedBranch } = useAppSelector((state) => state.user);
  const [confirmOrderQuery] = chaletApi.useConfirmOrderMutation();
  const [requestOrderUpdate] = chaletApi.useRequestOrderUpdateMutation();

  const [selectedTime, setSelectedTime] = useState<number | null>(null);
  const [isRejectSelect, setIsRejectSelect] = useState<boolean>(false);
  const [needsOrderUpdate, setNeedsOrderUpdate] = useState(false);

  const { palette } = useTheme();

  const generateOrderTimeEstimated = (initialTime: number) => {
    const times = [];
    times.push({
      id: 1,
      name: `${initialTime} min`,
      value: initialTime
    });
    for (let i = 1; i < 5; i++) {
      times.push({
        id: i + 1,
        name: `${initialTime + i * 10} min`,
        value: initialTime + i * 10
      });
    }
    return times;
  };

  const orderTimeEstimated = selectedBranch?.estimatedOrderTime
    ? generateOrderTimeEstimated(selectedBranch.estimatedOrderTime)
    : null;

  const handleTimeClick = (value: number) => {
    if (selectedTime === value) {
      setSelectedTime(null);
    } else {
      setSelectedTime(value);
    }
  };

  const isButtonSelected = (value: number) => {
    return value === selectedTime;
  };

  const rejectSelectedOpen = () => {
    setIsRejectSelect(true);
  };

  const rejectSelectedClose = () => {
    setIsRejectSelect(false);
  };

  useEffect(() => {
    if (!isModalOpen) {
      setSelectedTime(null);
    }
  }, [isModalOpen]);

  const confirmOrder = () => {
    if (selectedTime) {
      confirmOrderQuery({
        id: order.id,
        body: {
          estimatedPreparationTime: selectedTime
        }
      })
        .unwrap()
        .then(() => {
          toast.success('Orden confirmada correctamente');
        })
        .catch(() => {
          toast.error('Ocurrió un error al completar el paso');
        });
    }
  };

  return (
    <Modal open={isModalOpen} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          maxHeight: '90vh',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: '14px',
          textAlign: 'center',
          overflow: 'auto'
        }}>
        {isRejectSelect ? (
          <RejectOrder order={order} handleClose={rejectSelectedClose} />
        ) : (
          <Stack direction="column">
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              Nueva orden
            </Typography>
            <OrderDetailsView
              order={order}
              onOrderDetailUpdate={(needsUpdate) => {
                setNeedsOrderUpdate(needsUpdate);
              }}
              refetchOrders={refetchOrders}
              needsOrderUpdate={needsOrderUpdate}
              isWaitingUserConfirmation={isWaitingUserConfirmation}
            />
            {!needsOrderUpdate && !isWaitingUserConfirmation && (
              <>
                {orderTimeEstimated && (
                  <Card
                    elevation={0}
                    sx={{
                      marginTop: 2,
                      padding: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}>
                    <Typography variant="body1" sx={{ marginBottom: 2 }}>
                      Tiempo estimado de preparación
                    </Typography>
                    <CardContent
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
                        gap: '10px',
                        overflowX: 'auto',
                        width: '100%'
                      }}>
                      {orderTimeEstimated.map((time) => (
                        <Button
                          key={time.id}
                          variant={isButtonSelected(time.value) ? 'contained' : 'outlined'}
                          onClick={() => handleTimeClick(time.value)}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            overflow: 'auto',
                            whiteSpace: 'nowrap'
                          }}>
                          {time.name}
                        </Button>
                      ))}
                    </CardContent>
                  </Card>
                )}

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginTop: 2
                  }}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      rejectSelectedOpen();
                    }}>
                    Rechazar
                  </Button>
                  <Button
                    variant="contained"
                    disabled={selectedTime === null || needsOrderUpdate}
                    onClick={() => {
                      confirmOrder();
                    }}>
                    Confirmar orden
                  </Button>
                </Box>
              </>
            )}
          </Stack>
        )}
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            paddingTop: 1,
            paddingBottom: 1,
            paddingLeft: 2,
            paddingRight: 2,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 1
          }}>
          <PersonIcon />
          <Typography fontWeight={600}>{order.user.name}</Typography>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            backgroundColor: palette.primary.main,
            paddingTop: 1,
            paddingBottom: 1,
            paddingLeft: 2,
            paddingRight: 2,
            borderRadius: '0px 11px 0px 11px'
          }}>
          <Typography
            sx={{
              fontWeight: 600,
              color: 'white.main'
            }}>
            #{order.id}
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default OrderModal;
