import React, { useState } from 'react';
import { Box, Button, Card, CardContent, Typography, TextField } from '@mui/material';
import chaletApi from 'api/chaletApi';
import toast from 'react-hot-toast';
import { Order } from 'types/Order';

type RejectOrderProps = {
  order: Order;
  handleClose: () => void;
};

const RejectOrder: React.FC<RejectOrderProps> = ({ order, handleClose }) => {
  const [rejectOrderQuery] = chaletApi.useRejectOrderMutation();

  const [selectedReason, setSelectedReason] = useState<string | ''>('');
  const [otherReason, setOtherReason] = useState<string>('');

  const rejectedReason = [
    { id: 1, text: 'Sucursal cerrada' },
    { id: 2, text: 'No hay productos disponibles para completar la orden' },
    { id: 3, text: 'Sucursal con mucha demanda' },
    { id: 4, text: 'Otro motivo' }
  ];

  const handleReasonClick = (value: string) => {
    setSelectedReason(value);
    if (value !== 'Otro motivo') {
      setOtherReason('');
    }
  };

  const isReasonSelected = (value: string) => {
    return value === selectedReason;
  };

  const rejectOrder = () => {
    const reasonToSubmit = selectedReason === 'Otro motivo' ? otherReason : selectedReason;
    if (reasonToSubmit) {
      rejectOrderQuery({
        id: order.id,
        body: {
          reason: reasonToSubmit
        }
      })
        .then(() => {
          toast.success('Orden rechazada con éxito');
          handleClose();
        })
        .catch(() => {
          toast.error('Error al rechazar la orden');
        });
    }
  };

  return (
    <>
      <Card
        elevation={0}
        sx={{
          marginTop: 2,
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          Sentimos mucho que rechaces la orden.
        </Typography>
        <Typography variant="body2" sx={{ marginBottom: 2 }}>
          Por favor, selecciona el motivo.
        </Typography>
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            width: '100%'
          }}>
          {rejectedReason.map((reason) => (
            <Typography
              key={reason.id}
              onClick={() => handleReasonClick(reason.text)}
              variant="body1"
              sx={{
                cursor: 'pointer',
                textAlign: 'left',
                marginBottom: 1,
                border: 1,
                padding: 1,
                borderRadius: 1,
                bgcolor: isReasonSelected(reason.text) ? '#f1cdcb' : 'inherit',
                borderColor: isReasonSelected(reason.text) ? 'primary.main' : '#d1d5db',
                transition: 'all 0.5s'
              }}>
              {reason.text}
            </Typography>
          ))}
          {selectedReason === 'Otro motivo' && (
            <TextField
              label="Especificar otro motivo"
              type="text"
              value={otherReason}
              onChange={(e) => setOtherReason(e.target.value)}
              fullWidth
              sx={{ marginTop: 2 }}
            />
          )}
        </CardContent>
      </Card>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: 2
        }}>
        <Button variant="outlined" onClick={() => handleClose()}>
          Regresar
        </Button>
        <Button
          variant="contained"
          disabled={selectedReason === '' || (selectedReason === 'Otro motivo' && !otherReason)}
          onClick={() => rejectOrder()}>
          Rechazar
        </Button>
      </Box>
    </>
  );
};

export default RejectOrder;
