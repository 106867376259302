import React from 'react';
import { Box, Grid, Rating, Typography } from '@mui/material';
import { Feedback } from 'types/Feedback';

type CardFeedbackProps = {
  feedback: Feedback;
};

const FeedbackCard: React.FC<CardFeedbackProps> = ({ feedback }) => {
  return (
    <Grid item xs={12} sm={6} key={feedback.id}>
      <Box
        sx={{
          p: 2,
          border: '1px solid #ddd',
          borderRadius: 3,
          boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.08)',
          backgroundColor: '#ffffff',
          display: 'flex',
          flexDirection: 'column',
          gap: 1.5,
          height: '100%'
        }}>
        <Typography variant="subtitle1" fontWeight="bold">
          Feedback para Orden #{feedback.orderId}
        </Typography>

        <Typography variant="caption" color="textSecondary" mb={1}>
          <b>Fecha:</b>{' '}
          {new Date(feedback.createdAt).toLocaleDateString('es-ES', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
          })}
        </Typography>

        <Box>
          <Typography variant="body2" fontWeight="bold">
            Feedback de la Comida
          </Typography>
          <Box display="flex" alignItems="center" mt={0.5}>
            <Rating value={feedback.foodFeedback.rating} precision={0.5} readOnly />
          </Box>
          {feedback.foodFeedback.comments ? (
            <Typography variant="caption" color="textSecondary">
              {feedback.foodFeedback.comments}
            </Typography>
          ) : (
            <Typography variant="caption" color="textSecondary">
              Sin comentarios para la comida.
            </Typography>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default FeedbackCard;
