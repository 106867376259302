import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ChaletApiResponse } from 'types/ChaletApiResponse';
import { Order, ProductVariation, VariationOption } from 'types/Order';
import { Product } from 'types/Product';
import { ProductCategory } from 'types/ProductCategory';
import { Menu, Branch } from 'types/Shop';
import { BranchMenus, BranchMenusResponse } from 'types/BranchMenus';
import { BranchMenuCategories } from 'types/BranchMenuCategories';
import { BillingDetails } from 'types/BillingDetails';
import { Invoices } from 'types/Invoices';
import { FeedbackPagination } from 'types/FeedbackPagination';

const chaletApi = createApi({
  reducerPath: 'chaletApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/api`,
    prepareHeaders(headers, api) {
      const store: any = api.getState();
      headers.set('Authorization', `Bearer ${store.auth.token}`);
    }
  }),
  tagTypes: [
    'orders',
    'branch',
    'shops',
    'shop-menus',
    'shop-products',
    'shop-product',
    'complete-menu',
    'user',
    'billing-details',
    'invoices'
  ],

  endpoints: (builder) => ({
    login: builder.query({
      query: ({ email, password }) => ({
        method: 'POST',
        url: '/auth/login/branch',
        body: { email, password }
      })
    }),
    loginWithToken: builder.query({
      query: (token) => ({
        method: 'POST',
        url: '/auth/login/token',
        body: { token }
      })
    }),
    getActiveOrders: builder.query<Order[], number>({
      query: (branchId) => ({
        method: 'GET',
        url: '/orders/branch/active',
        params: { branchId }
      }),
      providesTags: (result, error, arg) =>
        result
          ? [...result.map(({ id }) => ({ type: 'orders' as const, id })), 'orders']
          : ['orders'],
      transformResponse: (response: ChaletApiResponse<Order[]>, meta, arg) => response.data
    }),
    getOrdersInRange: builder.query({
      query: ({ from, to, shopId }) => ({
        method: 'GET',
        url: '/orders/all-in-dates',
        params: { from, to, shopId }
      }),
      transformResponse: (response: ChaletApiResponse<Order[]>, meta, arg) => response.data
    }),
    getOrder: builder.query({
      query: (orderId) => ({
        method: 'GET',
        url: `/orders/${orderId}`
      })
    }),
    completeOrderStep: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/orders/${id}/complete-step/`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['orders'],
      transformResponse: (response: any, meta, arg) => response.data,
      transformErrorResponse: (response: any, meta, arg) => {
        const data = response.data;
        return data.message;
      }
    }),
    getUserById: builder.query({
      query: (userId) => ({
        method: 'GET',
        url: `/users/branch/${userId}`
      }),
      providesTags: ['user']
    }),
    getBranchById: builder.query<Branch, number>({
      query: (branchId) => ({
        method: 'GET',
        url: `/branches/${branchId}`
      }),
      providesTags: ['branch'],
      transformResponse: (response: ChaletApiResponse<Branch>, meta, arg) => response.data
    }),
    getAllShops: builder.query({
      query: () => ({
        method: 'GET',
        url: `/shops/all`
      }),
      providesTags: ['shops']
    }),
    getShopMenusForShop: builder.query<Menu[], number>({
      query: (shopId) => ({
        method: 'GET',
        url: `/shop-menus/forShop/${shopId}`
      }),
      providesTags: ['shop-menus'],
      transformResponse: (response: ChaletApiResponse<Menu[]>, meta, arg) => response.data
    }),

    saveBranchMenuCategory: builder.mutation({
      query: (body) => ({
        method: 'POST',
        url: `/branch-menu-categories`,
        body
      }),
      invalidatesTags: ['complete-menu']
    }),
    getProductsForCategoryMenu: builder.query<Product[], number>({
      query: (categoryMenuId) => ({
        method: 'GET',
        url: `/shop-products/forCategoryMenu/${categoryMenuId}`
      }),
      providesTags: (result) => {
        return result
          ? [
              ...result.map(({ id }) => ({ type: 'shop-products' as const, id })),
              { type: 'shop-products', id: 'LIST' }
            ]
          : [{ type: 'shop-products', id: 'LIST' }];
      },
      transformResponse: (response: ChaletApiResponse<Product[]>, meta, arg) => {
        const sortedProducts = response.data.sort((a, b) => a.id - b.id);
        return sortedProducts;
      }
    }),
    getProductCategories: builder.query<ProductCategory[], string>({
      query: (type) => ({
        method: 'GET',
        url: `/product-categories?type=` + type
      }),
      transformResponse: (response: ChaletApiResponse<ProductCategory[]>, meta, arg) =>
        response.data
    }),
    saveBranchMenuProduct: builder.mutation({
      query: (body) => ({
        method: 'POST',
        url: `/branch-menu-products`,
        body
      }),
      invalidatesTags: ['complete-menu'],
      transformResponse: (response: ChaletApiResponse<Product>, meta, arg) => response.data
    }),
    getProductById: builder.query<Product, number>({
      query: (id) => ({
        method: 'GET',
        url: `/shop-products/${id}`
      }),
      transformResponse: (response: ChaletApiResponse<Product>, meta, arg) => response.data,
      providesTags: (result) => {
        return result?.id ? [{ type: 'shop-product', id: result.id }] : ['shop-product'];
      }
    }),
    editShopProduct: builder.mutation({
      query: ({ productId, body }) => ({
        method: 'PUT',
        url: `/branch-menu-products/${productId}`,
        body
      }),
      invalidatesTags: ['complete-menu', 'shop-product'],
      transformResponse: (response: ChaletApiResponse<Product>, meta, arg) => response.data
    }),
    saveProductVariation: builder.mutation<ProductVariation, Partial<ProductVariation>>({
      query: (body) => ({
        method: 'POST',
        url: `/product-variations`,
        body
      }),
      invalidatesTags: ['shop-product'],
      transformResponse: (response: ChaletApiResponse<ProductVariation>, meta, arg) => response.data
    }),
    saveProductVariationOption: builder.mutation<VariationOption, Partial<VariationOption>>({
      query: (body) => ({
        method: 'POST',
        url: `/product-variation-options`,
        body
      }),
      invalidatesTags: (result) => {
        return ['shop-product'];
      },
      transformResponse: (response: ChaletApiResponse<VariationOption>, meta, arg) => response.data
    }),
    editBranchSchedule: builder.mutation({
      query: (body) => ({
        method: 'PUT',
        url: `/branches/update-schedule`,
        body
      }),
      invalidatesTags: ['branch']
    }),
    updateBranch: builder.mutation({
      query: ({ branchId, body }) => ({
        method: 'PUT',
        url: `/branches/${branchId}`,
        body
      }),
      invalidatesTags: ['branch'],
      transformResponse: (response: ChaletApiResponse<Branch>, meta, arg) => response.data
    }),
    getCompleteMenu: builder.query({
      query: (branchId) => ({
        method: 'GET',
        url: `/branch-menus/completeMenu`,
        params: { branchId }
      }),
      providesTags: ['complete-menu'],
      transformResponse: (response: ChaletApiResponse<BranchMenusResponse>) => {
        const sortedMenus = response.data.branchMenus.map((menu) => ({
          ...menu,
          categories: menu.categories.sort((a, b) => a.correlative - b.correlative)
        }));

        return {
          branchMenus: sortedMenus,
          needsSync: response.data.needsSync,
          missingCategories: response.data.missingCategories,
          missingProducts: response.data.missingProducts
        };
      }
    }),
    updateBranchMenuCategory: builder.mutation({
      query: ({ id, body }) => ({
        method: 'PUT',
        url: `/branch-menu-categories/${id}`,
        body
      }),
      invalidatesTags: ['complete-menu'],
      transformResponse: (response: ChaletApiResponse<BranchMenuCategories>) => response.data
    }),
    deleteBranchMenuProduct: builder.mutation({
      query: (id) => ({
        method: 'DELETE',
        url: `/branch-menu-products/${id}`
      }),
      invalidatesTags: ['complete-menu']
    }),
    updateUser: builder.mutation({
      query: ({ userId, body }) => ({
        method: 'PUT',
        url: `/users/${userId}`,
        body
      }),
      invalidatesTags: ['user']
    }),
    updateIsAvailableInBranch: builder.mutation({
      query: (body) => ({
        method: 'PUT',
        url: `/branch-menus/update/isAvailableInBranch`,
        body
      }),
      invalidatesTags: ['complete-menu']
    }),
    getDashboard: builder.query({
      query: ({ id, from, to }) => ({
        method: 'GET',
        url: `branches/dashboard/${id}`,
        params: { from, to }
      })
    }),
    updateProductVariation: builder.mutation({
      query: ({ id, body }) => ({
        method: 'PUT',
        url: `/product-variations/${id}`,
        body
      }),
      invalidatesTags: ['shop-product']
    }),
    deleteProductVariation: builder.mutation({
      query: (id) => ({
        method: 'DELETE',
        url: `/product-variations/${id}`
      }),
      invalidatesTags: ['shop-product']
    }),
    updateProductVariationOption: builder.mutation({
      query: ({ id, body }) => ({
        method: 'PUT',
        url: `/product-variation-options/${id}`,
        body
      }),
      invalidatesTags: ['shop-product']
    }),
    deleteProductVariationOption: builder.mutation({
      query: (id) => ({
        method: 'DELETE',
        url: `/product-variation-options/${id}`
      }),
      invalidatesTags: ['shop-product']
    }),
    getOrdersInRangeByBranch: builder.query<
      {
        totalOrders: number;
        totalPages: number;
        currentPage: number;
        orders: Order[];
      },
      {
        from: string;
        to: string;
        branchId: number;
      }
    >({
      query: ({ from, to, branchId }) => ({
        method: 'GET',
        url: '/orders/all-in-dates-by-branch',
        params: { from, to, branchId }
      })
    }),
    confirmOrder: builder.mutation({
      query: ({ id, body }) => ({
        method: 'POST',
        url: `/orders/${id}/confirm`,
        body
      }),
      invalidatesTags: ['orders']
    }),
    inProcessOrder: builder.mutation({
      query: ({ id }) => ({
        method: 'POST',
        url: `/orders/${id}/preparing`
      }),
      invalidatesTags: ['orders']
    }),
    readyForPickupOrder: builder.mutation({
      query: ({ id }) => ({
        method: 'POST',
        url: `/orders/${id}/ready`
      }),
      invalidatesTags: ['orders']
    }),
    rejectOrder: builder.mutation({
      query: ({ id, body }) => ({
        method: 'POST',
        url: `/orders/${id}/reject`,
        body
      }),
      invalidatesTags: ['orders']
    }),
    getProductsSold: builder.query({
      query: ({ id, from, to }) => ({
        method: 'GET',
        url: `/branches/products/solded/${id}`,
        params: { from, to }
      })
    }),
    createCopyMenu: builder.mutation({
      query: ({ branchId, shopId }) => ({
        method: 'POST',
        url: 'branch-menus/createBranchMenu',
        body: { branchId, shopId }
      }),
      invalidatesTags: ['complete-menu'],
      transformResponse: (response: ChaletApiResponse<BranchMenus>) => response
    }),
    addBranchMenuCategory: builder.mutation({
      query: (body) => ({
        method: 'POST',
        url: '/branch-menu-categories/from/shop-menu-category',
        body
      }),
      invalidatesTags: ['complete-menu']
    }),
    addBranchMenuProduct: builder.mutation({
      query: (body) => ({
        method: 'POST',
        url: '/branch-menu-products/from/shop-menu-category',
        body
      }),
      invalidatesTags: ['complete-menu']
    }),
    temporarilyCloseBranch: builder.mutation({
      query: ({ id, body }) => ({
        method: 'PATCH',
        url: `/branches/temporarily-close/${id}`,
        body
      }),
      invalidatesTags: ['branch', 'user']
    }),
    reopenBranch: builder.mutation({
      query: ({ id, body }) => ({
        method: 'PATCH',
        url: `/branches/reopen/${id}`,
        body
      }),
      invalidatesTags: ['branch', 'user']
    }),
    getBillingDetailByBranch: builder.query({
      query: ({ branchId }) => ({
        method: 'GET',
        url: `/billing-details/${branchId}`
      }),
      providesTags: ['billing-details'],

      transformResponse: (response: ChaletApiResponse<BillingDetails>) => response.data
    }),
    createBillingDetail: builder.mutation({
      query: ({ branchId, name, nit, dui, nrc, address, phone, email }) => ({
        method: 'POST',
        url: '/billing-details',
        body: {
          branchId,
          name,
          nit,
          dui,
          nrc,
          address,
          phone,
          email
        }
      }),
      invalidatesTags: ['billing-details']
    }),
    getInvoicesByBillingDetailId: builder.query<ChaletApiResponse<Invoices[]>, number>({
      query: (billingDetailId) => ({
        method: 'GET',
        url: `/invoices/${billingDetailId}`
      }),
      providesTags: ['invoices']
    }),
    getFeedbackByBranch: builder.query<
      ChaletApiResponse<FeedbackPagination>,
      { branchId: number; page: number; limit: number }
    >({
      query: ({ branchId, page, limit }) => ({
        method: "GET",
        url: `/feedbacks/by/branch/${branchId}`,
        params: { page, limit },
      }),
    }),
    getBranchFinancial: builder.query<Branch, number>({
      query: (branchId) => ({
        method: 'GET',
        url: `/branches/${branchId}/financial`,
      }),
      transformResponse: (response: ChaletApiResponse<Branch>) => response.data
    }),
    requestOrderUpdate: builder.mutation<Branch, {
      orderDetails: {[orderDetailId: number]: {isOutOfStock: boolean, notes: string}},
      orderId: number,
    }>({
      query: ({orderId, orderDetails}) => ({
        method: 'PUT',
        url: `/orders/${orderId}/request-update`,
        body: {
          orderDetails
        }
      }),
      transformResponse: (response: ChaletApiResponse<Branch>) => response.data
    })
  })
});

export default chaletApi;
